/**
 * Converts a jQuery Deferred object to a native Promise.
 *
 * @param deferred - The jQuery Deferred object to convert.
 * @returns A Promise that resolves or rejects based on the Deferred object's state.
 *
 * @example
 * // Convert a jQuery Deferred to a Promise
 * const jQueryDeferred = $.ajax({ url: 'https://api.example.com/data' });
 * const promise = deferredToPromise(jQueryDeferred);
 *
 * // Use the resulting Promise
 * promise
 *   .then(data => console.log('Data received:', data))
 *   .catch(error => console.error('Error:', error));
 *
 * @example
 * // Use with async/await
 * async function fetchData() {
 *   try {
 *     const jQueryDeferred = $.ajax({ url: 'https://api.example.com/data' });
 *     const data = await deferredToPromise(jQueryDeferred);
 *     console.log('Data received:', data);
 *   } catch (error) {
 *     console.error('Error:', error);
 *   }
 * }
 */
export function deferredToPromise<T>(
  deferred: JQuery.Deferred<T> | JQueryPromise<T>,
): Promise<T> {
  return Promise.resolve(deferred);
}
